import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/layout/Layout'
import Header from '../components/layout/Header'

const Rentmap = ({ location }) => {
    // const iframeOrigin = 'https://www.kontor.com/public/rentmap'
    useEffect(() => {
        navigate('/404', { replace: true })
    }, [])
    return (
        // <Layout gutters={false} container={false} activePage="/london-rentmap" location={location}>
        //      <Header
        //         title={`London Rentmap | Kontor`}
        //         description=""
        //     />
        //     <iframe
        //         title="Available Properties"
        //         src={iframeOrigin}
        //         loading='lazy'
        //         width="100vw"
        //         height="100vh"
        //         className="block"
        //         allow="clipboard-read"
        //         style={{
        //             marginBottom: '15rem',
        //             height: '100vh',
        //             width: '100vw',
        //             border: 'none',
        //             paddingTop: '100px'
        //         }}
        //     />
        // </Layout>
        <Layout location={location}>
            <Header title="404: Not found"/>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Layout>
    )
}

export default Rentmap;